<template>
	<section class="container">
		<div class="row mt-5">
			<div class="col-auto mx-auto">
				<div class="col-auto mx-auto">
					<router-link :to="{ name: 'CompanyKycAdd', params: { companyId: companyId } }">
						<b-button class="mb-3" color="green">Opret ny KYC</b-button>
					</router-link>
				</div>
			</div>
		</div>
		<b-card title="KYC">
			<div class="row">
				<div class="col-12">
					<BaseTable :items="kycList" :fields="fields" :isBusy="isBusy">
						<template v-slot:cell(kycDate)="data">
							{{ getDateAndMonth(data.item.kycDate) }}
						</template>

						<template v-slot:cell(kycPurpose)="data">
							<div v-if="data.item.kycPurpose === 'pension'">Pension</div>
							<div v-else-if="data.item.kycPurpose === 'companyFunds'">Selskabsmidler</div>
							<div v-else-if="data.item.kycPurpose === 'freeAssets'">Frie midler</div>
						</template>

						<template v-slot:cell(kycSigned)="data">
							<div v-if="data.item.kycSigned">
								<font-awesome-icon :icon="['fa', 'check']" class="icon fa-lg" style="color: green" />
							</div>
							<div v-else>
								<font-awesome-icon :icon="['fa', 'times']" class="icon fa-lg" style="color: red" />
							</div>
						</template>

						<template v-slot:cell(clientType)="data">
							<div v-if="data.item.clientType === 'private'">Privat</div>
							<div v-else-if="data.item.clientType === 'company'">Virksomhed (Reel ejer)</div>
						</template>

						<template v-slot:cell(remove)="data">
							<router-link :to="{ name: 'CompanyKycEdit', params: { companyId: companyId, index: data.index } }">
								<b-button class="mb-3" color="green">Ændr</b-button>
							</router-link>
							<BaseActionButton
								v-bind:clicked="clicked"
								v-bind:disabled="clicked"
								v-on:on-click="removeKyc(data.index)"
								type="button"
								id="signupButton"
								class="btn-delete"
							>
								Fjern KYC
							</BaseActionButton>
						</template>
					</BaseTable>
					<br />
					<div v-show="$can('read', 'Company', 'KycComment')">
						<label>
							<b>Kommentar</b>
						</label>
						<InputTextArea v-model="company.kycComment"></InputTextArea>
					</div>
					<div class="row">
						<div class="col-12 mt-5">
							<BaseActionButton
								class="btn-update"
								v-bind:clicked="clicked"
								v-bind:disabled="clicked"
								@on-click="onSubmit"
								v-if="$can('update', 'Company', 'CompanyInfo')"
							>
								{{ $t('global.update') }}
							</BaseActionButton>
						</div>
					</div>
				</div>
			</div>
		</b-card>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
import InputTextArea from '../../../global/atomComponents/InputTextArea.vue'

export default {
	name: 'CompanyAdminLis',
	mixins: [dateMixin],
	components: {
		BaseTable,
		InputTextArea,
	},
	data() {
		return {
			clicked: false,
			fields: [
				{
					key: 'firstName',
					label: i18n.t('employeeList.table.name'),
					sortable: true,
				},
				{
					key: 'lastName',
					label: 'Efternavn',
					sortable: true,
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
				},
				{
					key: 'secondaryEmail',
					label: 'Sekundær email',
					sortable: true,
				},

				{
					key: 'cpr',
					label: 'Cpr',
					sortable: true,
				},
				{
					key: 'kycSigned',
					label: 'Underskrevet',
					sortable: true,
				},
				{
					key: 'clientType',
					label: 'Klient type',
					sortable: true,
				},
				{
					key: 'kycPurpose',
					label: 'Formål',
					sortable: true,
				},
				{
					key: 'kycDate',
					label: 'Dato',
					sortable: true,
				},
				{
					key: 'evaluationInterval',
					label: 'Evalueringsinterval',
					sortable: true,
				},
				{
					key: 'remove',
					label: 'adminstrator',
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		onSubmit() {
			this.clicked = true
			this.$store
				.dispatch('companyVuex/updateCompany', { data: this.company, id: this.companyId })
				.then(() => {
					this.toast('Success', 'Kyc opdateret', true)
				})
				.catch((err) => {
					this.toast('Error', 'Noget gik galt' + err, false)
				})
				.finally(() => {
					this.clicked = false
				})
		},
		async removeKyc(index) {
			console.log(index)
			this.clicked = true
			// Remove kyc index from the company
			this.company.kycList.splice(index, 1)
			try {
				await this.$store.dispatch('companyVuex/updateCompany', { data: this.company, id: this.companyId })
				this.toast('Success', 'En KYC nu fjernet', true)
			} catch (err) {
				this.toast('Error', 'Noget gik galt' + err, false)
				this.clicked = false
			}
			this.clicked = false
		},
	},
	computed: {
		kycList() {
			return this.$store.getters['companyVuex/company']?.kycList ?? []
		},
		users() {
			return this.$store.getters['companyVuex/companyAdmins']
		},
		companyId() {
			return this.$route.params.companyId
		},
		company() {
			var company = this.$store.getters['companyVuex/company']
			return company
		},
		isBusy() {
			return !this.$store.getters['companyVuex/isReady']
		},
		id() {
			return this.$route.params.companyId
		},
	},
}
</script>

<style lang="scss"></style>
